
import React from 'react';
import './ParticipantInfoSheet.css';

const ParticipantInfoSheet = () => {
    return (
        <div className="participant-info-sheet">
            <h1>Participant Information Sheet (PIS) for Fitness and Nutrition Planning Study</h1>

            <h2>Invitation to Participate in Research</h2>
            <p>You are cordially invited to be a part of an innovative study focusing on the effectiveness of AI-generated versus expert-crafted fitness and meal plans. This pioneering research is a key component of our initiative to enhance the capabilities of Artificial Intelligence in the realm of personalized health and wellness planning. Your participation and insights are not only valued but crucial in shaping the future of health technology. We urge you to read this document thoroughly to understand the scope and significance of your involvement.</p>

            <h2>About the Research</h2>
            <p><strong>Research Conductors:</strong> This comprehensive study is spearheaded by Cherki Meziane and Phill Kasozi. The project is a collaborative effort involving a distinguished team of professionals specializing in machine learning, fitness, nutrition, and health sciences. Our combined expertise ensures a study that is both scientifically rigorous and relevant to real-world applications.</p>

            <h2>Purpose of the Research</h2>
            <p>The primary objective of this study is to methodically compare and analyze the effectiveness of fitness and meal plans generated by advanced AI algorithms with those crafted by experienced personal trainers. The insights derived from this comparative study are expected to significantly contribute to the enhancement of Language Learning Models (LLMs). These improvements aim to refine AI's performance in creating personalized health and fitness solutions, potentially revolutionizing how we approach personal wellness in the digital age.</p>

            <h2>Participant Criteria</h2>
            <ul>
                <li>Gender: Male</li>
                <li>Age Group: 18 to 35 years</li>
                <li>Interest: Specifically in muscle gain or size enhancement programs</li>
            </ul>

            <h2>Research Outcomes</h2>
            <p>The findings from this study are expected to make a substantial contribution to the academic field of AI and personal health planning. The results will be meticulously documented and published in reputable scientific journals. Furthermore, the outcomes will be presented at various academic and industry conferences, contributing to the broader discussion on the intersection of AI and health.</p>

            <h2>Ethics and Funding</h2>
            <p>This study has been rigorously reviewed and approved, ensuring adherence to the highest ethical standards in research. The project is generously funded ARUI, which supports innovative research in technology and health.</p>

            <h2>Detailed Participant Involvement</h2>
            <p><strong>Survey Participation:</strong> Your involvement in this study primarily includes completing a comprehensive online survey. This survey is designed to gather your feedback and experiences regarding the effectiveness, usability, and overall satisfaction with both AI-generated and expert-crafted fitness and meal plans. The survey is expected to take approximately 15 minutes and can be completed at your convenience from any internet-enabled device.</p>

            <h2>Data Protection and Anonymity</h2>
            <p>We place utmost importance on the privacy and security of your personal data. All information collected during this study will be anonymized and securely stored, complying with Italy(EU) data protection laws and regulations. The anonymized data may be utilized for further research and development of AI language models, with a strict commitment to maintaining privacy and ethical standards in all aspects of data handling.</p>

            <h2>Compensation</h2>  
            <p>While there is no financial compensation for participating in this study, your contribution is highly valuable to us. Your involvement provides critical insights that aid in advancing the fields of health, fitness, and AI research. Your participation is contributing to a future where technology and health converge for better personal wellness solutions.</p>

            <h2>Voluntary Participation</h2>
            <p>We emphasize that your participation in this study is entirely voluntary. You have the freedom to withdraw from the study at any stage, without any need to provide a reason and without any detriment to yourself.</p>

            <h2>Contact Information</h2>
            <p>Should you have any questions about the study, or if you wish to participate, please feel free to contact us:<br />
            Email: cm1557@student.aru.ac.uk<br />
            Phone Number: 07925478373</p>
        </div>
    );
};

export default ParticipantInfoSheet;
